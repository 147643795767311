@import "@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as";
@import "@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing";
@import "@JohnLewisPartnership/jlfs-ui-library/dist/styles/breakpoints.scss";
@import "@JohnLewisPartnership/style-dictionary/dist/scss/financial-services/_colour.scss";

.orderNowButton {
  padding: spacing(1) spacing(2) !important;
  font-size: 20px !important;
  line-height: 28px !important;
  font-weight: 400 !important;
}

.currencyCalculator {
  width: 100%;
  margin: spacing(1) 0;

  @include breakpointFS(large) {
    min-width: 848px;
    margin: 0;
  }

  @include breakpointFS(xl) {
    min-width: 1224px;
    padding: 0 spacing(13);
  }

  .topSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: spacing(1);

    @include breakpointFS(medium) {
      align-items: center;
      padding-bottom: 0;
    }

    .title {
      @include display-as("heading-m");
      margin-bottom: spacing(2);

      @include breakpointFS(large) {
        margin-top: spacing(5);
        @include display-as("heading-l");
      }
    }

    .subheading {
      @include display-as("body-l");
      color: #4a4a4a;
    }
  }

  .bottomSection {
    margin-top: spacing(3);

    @include breakpointFS(medium) {
      margin-top: spacing(5);
    }

    @include breakpointFS(large) {
      margin-top: spacing(6);
    }

    @include breakpointFS(xl) {
      margin-top: spacing(7);
    }

    .inputSection {
      margin-top: spacing(3);
      margin-bottom: spacing(3);
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      @include breakpointFS(medium) {
        margin-bottom: spacing(2);
        flex-direction: row;
        justify-content: space-evenly;
        align-items: normal;
      }

      @include breakpointFS(large) {
        margin-bottom: spacing(3);
      }

      @include breakpointFS(xl) {
        margin-bottom: spacing(4);
      }

      .inputContainer {
        width: 100%;
      }

      .separator {
        @include display-as("heading-m");
        margin: spacing(1) 0;

        @include breakpointFS(medium) {
          margin: spacing(5) spacing(1.5) 0 spacing(1.5);
        }
      }
    }

    .onlineRate {
      @include display-as("heading-s");
      margin-top: spacing(3);
      margin-bottom: spacing(4);

      .sellRate {
        @include display-as("body-l");
      }

      @include breakpointFS(medium) {
        margin-bottom: spacing(5);
      }

      @include breakpointFS(large) {
        margin-top: spacing(4);
        margin-bottom: spacing(2);
      }

      @include breakpointFS(xl) {
        margin-top: spacing(5);
      }
    }

    .ctaContainer {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      height: spacing(13);

      @include breakpointFS(medium) {
        flex-direction: row;
        height: unset;
      }
    }
  }
}

.errorMessage {
  margin-top: spacing(4);
}

.lozenge {
  width: 100% !important;
  @include breakpointFS(large) {
    width: fit-content !important;
  }
}

.content {
  padding: 0 spacing(1);
  @include breakpointFS(medium) {
    padding: 0 spacing(1.5);
  }
}
