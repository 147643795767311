@import "@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing";
@import "@JohnLewisPartnership/jlfs-ui-library/dist/styles/breakpoints.scss";

.helpSection {
  margin: spacing(10) spacing(3);

  @include breakpointFS(large) {
    margin-left: auto;
    margin-right: auto;
    width: getMinWidth(large);
  }

  @include breakpointFS(xl) {
    width: getMinWidth(xl);
  }
}
